<template>
  <div>
    <div class="list course">
      <img class="no_data" src="../../assets/images/no_data.png" v-if="data.total == 0" />
      <router-link :to="{ name: 'encoursedetail', params: { id: item.id } }" class="item" v-for="(item, index) in data.list"
        :key="index">
        <img class="img" :src="_this.utils.HandleUrl(item.img_url,1)" />
        <div class="title">{{ item.title }}</div>
        <div class="logo">
          <span class="num">{{ item.total_learn }}人学习 可试看</span>
        </div>
      </router-link>
    </div>

    <div class="pagination_box" v-if="data.total>15">
      <el-pagination v-model:currentPage="data.pageindex" background layout="prev, pager, next, jumper"
        :page-size="data.pagesize" :total="data.total" />
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance,reactive, defineProps, watch } from 'vue';

const _this = getCurrentInstance().appContext.config.globalProperties;
const props = defineProps(['keyword', 'selected_sort','selected_category']);

const data = reactive({
  pageindex: 1,
  pagesize: 15,
  total: 0,
  list: []
});


watch([() => props.selected_sort, () => props.keyword, () => props.selected_category], () => {
  if (data.pageindex==1){
    GetList();
  }else {
    data.pageindex = 1;
  }

});

watch(() => data.pageindex, () => {
  GetList();
});

function GetList() {
  var url = "/Open/GetCourseList";
  _this.$http.post(url, { pageindex: data.pageindex, pagesize: data.pagesize, keyword: props.keyword, sort_type: props.selected_sort, category_id: props.selected_category }).then(function (res) {
    if (res.data.status == 1) {
      data.list = res.data.ds;
      data.total = res.data.total;
    }
  }.bind(this)).catch(function () {
  });
}

GetList();

</script>
